<template>
  <div class="table">
    <div class="head">
      <span>ID</span>
      <span>Игра</span>
      <span>Статус</span>
      <span>Ставка</span>
      <span>X</span>
      <span>Выигрыш</span>
    </div>
    <transition-group name="fade" tag="div">
      <div v-for="game in games" :key="game.id" class="game">
        <span>#{{ game.id }}</span>
        <span>
          <img class="default" alt="dice" src="@/assets/icons/dice-gray.svg" />
          Dice
        </span>
        <span v-if="game.status === 'win'" class="success">Выиграно</span>
        <span v-else class="danger">Проиграно</span>
        <span>
          {{ convertAmount(game.price, game.currency) }}
          <img class="coin" alt="coin" src="@/assets/icons/header-coin.png" />
        </span>
        <span>X{{ game.x }}</span>
        <span
          :class="{
            success: game.status === 'win',
            danger: game.status === 'lose',
          }"
        >
          {{ convertAmount(game.win, game.currency) }}
          <img class="coin" alt="coin" src="@/assets/icons/header-coin.png" />
        </span>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      games: [],
    };
  },
  computed: mapGetters("user", ["convertAmount"]),
  async created() {
    try {
      let { data } = await this.$http.get("games/dices/classic/history");
      this.games = data.response;
    } catch (error) {
      this.$toast.error(error?.message);
    }
  },
};
</script>

<style lang="scss" scoped>
.table {
  .head {
    display: flex;
    margin-bottom: 15px;
    padding: 0 20px;
    span {
      width: calc(100% / 6);
      color: #a1a5bc;
      //   font-weight: 500;
      font-size: 14px;
      display: flex;
      justify-content: center;
      &:last-child {
        justify-content: flex-end;
      }
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        justify-content: flex-start;
      }
    }
  }
  .game {
    display: flex;
    border: 1px solid #e7eef5;
    border-radius: 6px;
    height: 55px;
    margin-bottom: 15px;
    align-items: center;
    padding: 0 20px;
    span {
      width: calc(100% / 6);
      color: #000000;
      //   font-weight: 500;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        justify-content: flex-end;
      }
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        justify-content: flex-start;
      }
      &.success {
        color: #57c400;
      }
      &.danger {
        color: #ef4141;
      }
      img.default {
        margin-right: 10px;
      }
      img.coin {
        margin-left: 10px;
        width: 20px;
      }
    }
  }
}
</style>
